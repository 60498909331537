<template>
  <div class="view-wrap">
    <loading v-if="loading" :loading="loading"></loading>
    <div v-if="!loading" class="container">
      <div class="row">
        <div class="col-12">
          <div class="home-redeem">
            <div class="home-redeem__top">
              <div class="home-redeem__icon">
                <img src="../../src/assets/present.svg">
              </div>
              <div class="home-redeem__top-content">
                <h1>Congratulations!</h1>
                <p class="home-redeem__balance">You have <span>{{ formattedBalance }}</span> points you can redeem!</p>
                <p class="home-redeem__balance-desc">You can redeem your points for codes and vouchers to use at leading high street stores.</p>
                <router-link to="/rewards" class="button button--primary">
                  Redeem your reward
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Loading from "@/components/Loading";
import { useStore } from '@/store';

export default {
  name: 'HomeView',
  components: {
    Loading
  },
  
  data() {
    return {
        loading : true
    }
  },
  methods: {

  },
  mounted() {
    const store = useStore();
    this.balance = store.balance;

    setTimeout(() => (
        this.loading = false
    ), 1000);
  },
  computed : {
    formattedBalance() {
        return this.balance.toLocaleString('en-US'); 
    },
  }
}
</script>

<style lang="scss" scoped>

@use "@/scss/mixins.scss";
@use "@/scss/variables.scss";
@import "../scss/styles.scss";

.home-redeem{
  padding: 0.5rem 1rem 0rem 1rem;
  p{
    color: white;
  }
  &__top{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include bp(md){
      flex-wrap: nowrap;
    }
    &-content{
      width: 100%;
      @include bp(md){
        flex: 1;
        padding-left: 10rem;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: center;
        justify-content: center;
        flex-direction: column;
      }
      h1, p{
        @include bp(md){
          text-align: left;
        }
      }
    }
  }
  &__icon{
    width: 14rem;
    height: 14rem;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.2rem;
    @include bp(md){
      width: 34rem;
      height: 34rem;
    }
    img{
      width: 6rem;
      @include bp(md){
        width: 15rem;
      }
    }
  }
  &__balance{
    font-size: 2.2rem;
    line-height: 3rem;
    font-weight: 300;
    text-align: center;
    @include bp(md){
      font-size: 3rem;
      line-height: 3.8rem;
    }
    &-desc{
      margin-top: 1rem;
      text-align: center;
      @include bp(md){
        max-width: 50rem;
      }
    }
    span{
      font-weight: 600;
    }
  }
  .button{
    width: 100%;
    margin-top: 2rem;
    @include bp(md){
      width: auto;
    }
  }

}

</style>
