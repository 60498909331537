import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: HomeView
  },
  {
    path: '/rewards',
    name: 'Rewards',
    component: () => import(/* webpackChunkName: "rewards" */ '../views/RewardView.vue')
  },
  {
    path: '/rewards/:slug',
    name: 'Reward',
    component: () => import(/* webpackChunkName: "single reward" */ '../views/Reward-Single.vue')
  },
  {
    path: '/rewards/:slug/confirm',
    name: 'RewardConfirm',
    component: () => import(/* webpackChunkName: "single reward" */ '../views/RewardConfirm.vue')
  },
  {
    path: '/rewards/:slug/redeem',
    name: 'RewardRedeem',
    component: () => import(/* webpackChunkName: "single reward" */ '../views/RewardRedeem.vue')
  },
  {
    path: '/redeem_complete',
    name: 'RedeemComplete',
    component: () => import(/* webpackChunkName: "single reward" */ '../views/RedeemComplete.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "single reward" */ '../views/Account.vue')
  },
]
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
 

export default router
