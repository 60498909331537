<template>
  <div class="main-wrap">
    <header class="site-header">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="site-header__content">
              <div class="site-header__left">
                <router-link to="/account">
                  <img alt="VivaScore Rewards" src="../src/assets/user.svg">
                </router-link>
              </div>
              <div class="site-header__logo">
                <router-link to="/">
                  <img alt="VivaScore Rewards" src="../src/assets/fizz-logo.png">
                </router-link>
              </div>
              <div class="site-header__right">
                <img alt="VivaScore Rewards" src="../src/assets/menu.svg">
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <router-view></router-view>
    <footer class="site-footer">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <ul>
              <li>
                <a href="#">Privacy policy</a>
              </li>
              <li>
                <a href="#">Cookie policy</a>
              </li>
              <li>
                <a href="#">Terms & conditions</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>


<script>

import { useStore } from '@/store';

export default { 
  components: {
  },
  data() {
    return {
    }
  },

  mounted() {
    const store = useStore();
    if(store.balance < 10000){
      store.balance = 200000;
    }
  },
 
  created() {
  
  },

  methods: {

  },

 
}
</script>




<style lang="scss">
@use "@/scss/mixins.scss";
@use "@/scss/variables.scss";
@import "scss/styles.scss";


.site-header{
  width: 100%;
  padding: 2rem 0;
  &__content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 3rem;
  }
  &__logo{
    max-width: 16rem;
    @include bp(md){
      order: 1;
      max-width: 100%;
      flex: 1;
    }
    img{
      max-width: 16rem;
      @include bp(md){
        max-width: 21rem;
      }
    }
  }
  &__left, &__right{
    max-width: 3rem;
    @include bp(md){
      order: 2;
    }
  }
}

.site-footer{
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 14rem;
  ul{
    display: flex;
    justify-content: center;
    li{
      list-style-type: none;
      a{
        color:white;
        font-size: 1.1rem;
        margin: 0 0.6rem;
      }
    }
  }
}

</style>


