import { defineStore } from 'pinia';

export const useStore = defineStore({
  id: 'store_data',
  state: () => ({
    voucher_amount: '',
    order_history: [],
    balance: 200000
  }),
  actions: {
  },
  getters: {
    // Define getters if needed
  },
  mutations: {
    setVoucherAmount(state, amount) {
      state.voucher_amount = amount;
    },
    addToOrderHistory(state, history) { 
      state.order_history = history;
    }
  },
  persist: {
    enabled: true
  }
  // You can also define mutations, but Pinia automatically generates them
});