import {computed, createApp, ref} from 'vue';
import App from './App.vue';
import router from './router';
import axios from "axios";
import { createPinia } from 'pinia';
import { useStore } from "@/store";
import piniaPersist from 'pinia-plugin-persist'

const pinia = createPinia()
pinia.use(piniaPersist)

function setVoucherAmount() {
    const store = useStore();
    store.voucher_amount = '';
}

function addToOrderHistory() {
    const store = useStore();
    store.order_history = '';
}

const app = createApp(App);

app.config.globalProperties.$setVoucherAmount = setVoucherAmount;
app.config.globalProperties.$addToOrderHistory = addToOrderHistory;

app.use(pinia).use(router).mount('#app');