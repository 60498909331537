<template>
    <div v-if="loading" class="loading-spinner" :class="size">
        <img src="../assets/spinner.svg">
    </div>
  </template>
  
  <script>
  export default {
    name: 'Loading',
    props: {
      loading : false,
      size: {
        type: String,
      },
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  
  .loading-spinner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    grid-column: 1 / -1;
    height: 100%;
    img{
      width: 5rem;
    }
    &.page{
      img{
        width: 15rem;
      }
    }
    &.component{
      height: 100%;
      img{
        width: 9rem;
      }
    }
    &.small{
      img{
        width: 5rem;
      }
    }
  }
  
  </style>
  